import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './Login.css'; // Import the external CSS file

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear any previous error

        if (!username || !password) {
            setError('Please fill in both fields.');
            return;
        }

        try {
            const csrfToken = Cookies.get('csrftoken');
            const response = await axios.post(
                'http://localhost:8000/api/login/',
                { username, password },
                {
                    headers: {
                        'X-CSRFToken': csrfToken,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                const { redirect, token } = response.data;
                localStorage.setItem('token', token);

                // Redirect based on user type
                switch (redirect) {
                    case 'client_dashboard':
                        window.location.href = '/client_dashboard';
                        break;
                    case 'delivery_dashboard':
                        window.location.href = '/delivery_dashboard';
                        break;
                    case 'admin_dashboard':
                        window.location.href = '/admin_dashboard';
                        break;
                    default:
                        setError('Unexpected user type.');
                        break;
                }
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data.error || 'An error occurred.');
            } else if (error.request) {
                setError('No response received from server.');
            } else {
                setError('Error in setting up the request.');
            }
        }
    };

    return (
        <div className="page">
            <div className="container">
                <div className="imageContainer">
                    <img
                        src="https://img.freepik.com/free-vector/login-concept-illustration_114360-739.jpg"
                        alt="Login Illustration"
                        className="image"
                    />
                </div>
                <div className="formContainer">
                    <h2 className="heading">Login</h2>
                    <form onSubmit={handleSubmit} className="form">
                        <div className="formGroup">
                            <label htmlFor="username" className="label">Username:</label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="input"
                                required
                            />
                        </div>
                        <div className="formGroup">
                            <label htmlFor="password" className="label">Password:</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="input"
                                required
                            />
                        </div>
                        <button type="submit" className="button">Login</button>
                    </form>
                    {error && <p className="error">{error}</p>}
                    <div className="socialMediaContainer">
                        <p className="socialText">Or login with:</p>
                        <div className="iconContainer">
                            <button className="iconButton">
                                <img
                                    src="https://img.icons8.com/ios-filled/50/000000/google-logo.png"
                                    alt="Google"
                                    className="icon"
                                />
                            </button>
                            <button className="iconButton">
                                <img
                                    src="https://img.icons8.com/ios-filled/50/000000/facebook-new.png"
                                    alt="Facebook"
                                    className="icon"
                                />
                            </button>
                            <button className="iconButton">
                                <img
                                    src="https://img.icons8.com/ios-filled/50/000000/twitter-circled.png"
                                    alt="Twitter"
                                    className="icon"
                                />
                            </button>
                        </div>
                    </div>
                    <p className="signupText">
                        Don't have an account? <a href="/signup" className="signupLink">Sign Up</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
