import React from 'react';
import { Outlet } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
import { FaUserPlus, FaUserCheck, FaClock, FaCalendarAlt } from 'react-icons/fa';
import './Appointment.css'; // Import the external CSS file

const Appointments = () => {
  const appointmentData = [
    { category: 'Statistics', admitted: 120, discharged: 80, underObservation: 40, todaysAppointments: 25, upcomingAppointments: 60, canceledAppointments: 5, doctors: 45, nurses: 85, supportStaff: 30 },
    { category: 'Cardiology', admitted: 50, discharged: 30, underObservation: 15, todaysAppointments: 10, upcomingAppointments: 25, canceledAppointments: 2, doctors: 12, nurses: 20, supportStaff: 5 },
    { category: 'Neurology', admitted: 30, discharged: 20, underObservation: 5, todaysAppointments: 5, upcomingAppointments: 15, canceledAppointments: 1, doctors: 8, nurses: 15, supportStaff: 3 },
    { category: 'Pediatrics', admitted: 70, discharged: 50, underObservation: 20, todaysAppointments: 15, upcomingAppointments: 30, canceledAppointments: 3, doctors: 10, nurses: 25, supportStaff: 7 },
    { category: 'Orthopedics', admitted: 40, discharged: 25, underObservation: 10, todaysAppointments: 8, upcomingAppointments: 20, canceledAppointments: 4, doctors: 5, nurses: 15, supportStaff: 4 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A569BD'];
  
  const cardData = [
    { title: 'Total Admitted', value: appointmentData.reduce((acc, data) => acc + data.admitted, 0), icon: <FaUserPlus /> },
    { title: 'Total Discharged', value: appointmentData.reduce((acc, data) => acc + data.discharged, 0), icon: <FaUserCheck /> },
    { title: 'Total Appointments Today', value: appointmentData.reduce((acc, data) => acc + data.todaysAppointments, 0), icon: <FaClock /> },
    { title: 'Total Upcoming Appointments', value: appointmentData.reduce((acc, data) => acc + data.upcomingAppointments, 0), icon: <FaCalendarAlt /> },
  ];

  return (
    <div className="dashboardContainer">
      <br />
      <div className="tableContainer">
        <h1 className="header">Appointments</h1>
        <div className="responsiveTableWrapper">
          <table className="table">
            <thead>
              <tr>
                <th className="tableHeader">Category</th>
                <th className="tableHeader">Admitted</th>
                <th className="tableHeader">Discharged</th>
                <th className="tableHeader">Under Observation</th>
                <th className="tableHeader">Today’s Appointments</th>
                <th className="tableHeader">Upcoming Appointments</th>
                <th className="tableHeader">Canceled Appointments</th>
                <th className="tableHeader">Doctors</th>
                <th className="tableHeader">Nurses</th>
                <th className="tableHeader">Support Staff</th>
              </tr>
            </thead>
            <tbody>
              {appointmentData.map((data, index) => (
                <tr key={index} className={index % 2 === 0 ? 'evenRow' : 'oddRow'}>
                  <td className="tableCell">{data.category}</td>
                  <td className="tableCell">{data.admitted}</td>
                  <td className="tableCell">{data.discharged}</td>
                  <td className="tableCell">{data.underObservation}</td>
                  <td className="tableCell">{data.todaysAppointments}</td>
                  <td className="tableCell">{data.upcomingAppointments}</td>
                  <td className="tableCell">{data.canceledAppointments}</td>
                  <td className="tableCell">{data.doctors}</td>
                  <td className="tableCell">{data.nurses}</td>
                  <td className="tableCell">{data.supportStaff}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="cardsContainer">
        {cardData.map((card, index) => (
          <div key={index} className="card">
            <div className="cardIcon">{card.icon}</div>
            <h3 className="cardTitle">{card.title}</h3>
            <p className="cardValue">{card.value}</p>
          </div>
        ))}
      </div>

      {/* Charts */}
      <div className="chartContainer">
        {/* Bar Chart */}
        <div className="cardContainer">
          <BarChart width={300} height={300} data={appointmentData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="admitted" fill="#82ca9d" />
            <Bar dataKey="discharged" fill="#8884d8" />
            <Bar dataKey="underObservation" fill="#ffc658" />
          </BarChart>
        </div>
        <div className="cardContainer">
          {/* Line Chart */}
          <LineChart width={300} height={300} data={appointmentData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="admitted" stroke="#8884d8" />
            <Line type="monotone" dataKey="todaysAppointments" stroke="#82ca9d" />
          </LineChart>
        </div>
        <div className="cardContainer">
          {/* Pie Chart */}
          <PieChart width={300} height={300}>
            <Pie
              data={appointmentData}
              dataKey="todaysAppointments"
              nameKey="category"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              label
            >
              {appointmentData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default Appointments;
