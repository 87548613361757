import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Home.css'; // Import the external CSS file


const Home = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [feedbackEmail, setFeedbackEmail] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const images = [
    'https://docpulse.com/wp-content/uploads/2024/02/Apolo-Ayurvaid.jpg',
    'https://img.freepik.com/free-vector/health-care-logo-icon_125964-471.jpg?semt=ais_hybrid',
    'https://www.logopeople.in/wp-content/uploads/2023/07/Inspiring-Hospital-Logo-Design-4.jpg',
    'https://www.logopeople.in/wp-content/uploads/2023/07/Elegance-1-1.jpg',
    'https://img.freepik.com/free-vector/hospital-logo-template_23-2150529366.jpg?w=360',
  ];

  useEffect(() => {
    axios.get('http://localhost:8000/api/productview/')
      .then(response => {
        setProducts(response.data);
        setFilteredProducts(response.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, []);
  const handleFeedbackSubmit = (event) => {
    event.preventDefault();
    // Here, you can handle the feedback submission to your backend
    console.log('Feedback submitted:', { feedbackEmail, feedbackMessage });
    // Clear the form
    setFeedbackEmail('');
    setFeedbackMessage('');
    alert('Feedback submitted successfully!');
  };


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, [images.length]);

  const getCSRFToken = () => {
    let csrfToken = null;
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === 'csrftoken') {
        csrfToken = value;
        break;
      }
    }
    return csrfToken;
  };

  const handleAddToCart = (productId) => {
    if (!isAuthenticated()) {
      navigate('/login');
      return;
    }

    const csrfToken = getCSRFToken();
    axios.post(`http://localhost:8000/api/add_to_cart/${productId}/`, {}, {
      headers: {
        'X-CSRFToken': csrfToken,
      }
    })
      .then(response => {
        setMessage(response.data.message);
        setTimeout(() => {
          setMessage(null);
        }, 3000);
        navigate('/cart');
      })
      .catch(error => {
        console.error('Error adding to cart: ', error);
      });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterProducts(event.target.value);
  };

  const filterProducts = (term) => {
    if (!term) {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const isAuthenticated = () => {
    const token = localStorage.getItem('authToken');
    return !!token;
  };

  return (
    <div className="pageContainer">
      
      <div style={styles.topImageContainer}>
      <img
        src="https://img.freepik.com/free-photo/team-doctors-meeting_107420-84805.jpg?ga=GA1.1.1627994520.1702022316&semt=ais_hybrid"
        alt="Top Banner"
        style={styles.topImage}
      />
      <div style={styles.overlayText}>
        <h1>Hospital Management System</h1>
        <p>Streamline your appointments, billing, and patient care with ease.</p>
      </div>
    </div>
      <br />
      
     
      <br />
      <div>
  <header className="header1">
    <h1 className="title">Medical Practice Management Software</h1>
    <br/>
    <div className="paragraph-container">
      <div className="paragraph-card">
        <div className="card-icon">📋</div>
        <p>
          One-stop solution to manage all the aspects of OPD starting from
          patient registration to generating digital prescriptions, bills, and
          revenue analysis.
        </p>
      </div>
      <div className="paragraph-card">
        <div className="card-icon">🧪</div>
        <p>
          Pharmacy and Lab management is also simplified, enabling doctors to
          view lab reports in the software and keep track of inventory levels.
        </p>
      </div>
      <div className="paragraph-card">
        <div className="card-icon">📄</div>
        <p>
          Customisable medical case sheets allow you to create and maintain
          electronic medical records (EMR) tailored to specific patient needs.
        </p>
      </div>
      <div className="paragraph-card">
        <div className="card-icon">🖥️</div>
        <p>
          Generate detailed revenue analytics and reports to track financial
          performance, helping optimize your practice's profitability.
        </p>
      </div>
    </div>
  </header>
  <main>
  <div className="steps-container">
    <div className="step">
      <div className="icon icon-blue">📅</div>
      <p className="text">Doctor Appointment</p>
    </div>
    <div className="step">
      <div className="icon icon-yellow">💵</div>
      <p className="text">Billing</p>
    </div>
    <div className="step">
      <div className="icon icon-red">💉</div>
      <p className="text">Treatment</p>
    </div>
    <div className="step">
      <div className="icon icon-purple">🔁</div>
      <p className="text">Follow-up Visits</p>
    </div>
    <div className="step">
      <div className="icon icon-green">⭐</div>
      <p className="text">Patient Satisfaction</p>
    </div>
  </div>
</main>

</div>



   



      <br />
      <div className="cards">
  <div className="card">
    <img src="https://media.istockphoto.com/id/1903423742/photo/medical-team-meeting.webp?a=1&b=1&s=612x612&w=0&k=20&c=eI6bQ--x2NBPLM4SAuWDpZ1-HcOoUWj-ND04wX7erdA=" alt="Card 1" />
    <h3>CMS Software</h3>
    <p>Manage your clinic's operations seamlessly with our advanced Clinic Management Software (CMS), designed to enhance efficiency.</p>
  </div>

  <div className="card">
    <img src="https://images.unsplash.com/photo-1516549655169-df83a0774514?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGhvc3BpdGFsJTIwbWFuYWdlbWVudHxlbnwwfHwwfHx8MA%3D%3D" alt="Card 2" />
    <h3>PMS Software</h3>
    <p>Our Patient Management Software (PMS) helps doctors and healthcare providers manage patient records and appointments easily.</p>
  </div>

  <div className="card">
    <img src="https://images.unsplash.com/photo-1601841197690-6f0838bdb005?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGhvc3BpdGFsJTIwbWFuYWdlbWVudHxlbnwwfHwwfHx8MA%3D%3D" alt="Card 3" />
    <h3>HIMS Software</h3>
    <p>Hospital Integrated Management Software (HIMS) to streamline hospital operations, including billing, records, and management.</p>
  </div>
</div>
<br/>
<br/>


     

       {/* Carousel showing 3 images at a time */}
       <div style={styles.imageCarousel}>
        <button onClick={handlePrevious} style={styles.arrowButton}>◀</button>
        <div style={styles.carouselContainer}>
          {images.slice(currentIndex, currentIndex + 3).map((src, index) => (
            <img key={index} src={src} alt={`Carousel ${index}`} style={styles.carouselImage} />
          ))}
        </div>
        <button onClick={handleNext} style={styles.arrowButton}>▶</button>
      </div>
      <br />


      <div className="heading-container">
  <h1 className="heading">Hospital Management System</h1>
  <p className="description">
    <em>
      Hospital Systems is a comprehensive and integrated Hospital Management System tailored to meet the needs of hospital systems, medical centers, multi-specialty clinics, and medical practitioners. This all-encompassing system connects hospitals, satellite clinics, and medical stores through its Multi-Location functionality. Unlike traditional paper-based methods, it leverages technology to pull up server or cloud information swiftly, ensuring top performance.
      <br /><br />
      Our user-friendly EHR Software eliminates handwriting errors and allows easy access to revenue streams, patient records, and essential real-time metrics. The software also facilitates the electronic sharing of patient records, empowering both patients and providers to view lab results, chat securely, and schedule appointments online.
      <br /><br />
      A customizable alert system sends reminders via text, IM, and email to enhance patient care. Booking appointments with doctors based on their specialty, rating, fees, and availability is simple. Plus, organizing doctor schedules, compiling patient notes, and handling payments become effortless tasks.
      <br /><br />
      With eHospital – Hospital Management System, doctors, and patients can check their calendars on mobile devices, contributing to a more organized life. The software is available for both on-premise and on-cloud installations, all built on best practices from around the globe.
    </em>
  </p>
</div>
<br/>



<div className="cards">
  <div className="card">
    <img src="https://media.istockphoto.com/id/1356562845/photo/happy-doctor-leading-a-team-of-healthcare-workers-at-the-hospital.jpg?s=612x612&w=0&k=20&c=IMAkynSYgxfvO0Mo8s0yOj8245nelXyt4Z0IrgEZnik=" alt="Card 1" />
    <h3>CMS Software</h3>
    <p>Manage your clinic's operations seamlessly with our advanced Clinic Management Software (CMS), designed to enhance efficiency.</p>
  </div>

  <div className="card">
    <img src="https://img.freepik.com/premium-photo/health-facility-interior-patient-room-setup_153608-17571.jpg" alt="Card 2" />
    <h3>PMS Software</h3>
    <p>Our Patient Management Software (PMS) helps doctors and healthcare providers manage patient records and appointments easily.</p>
  </div>

  <div className="card">
    <img src="https://images.unsplash.com/photo-1516549655169-df83a0774514?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGhvc3BpdGFsJTIwbWFuYWdlbWVudHxlbnwwfHwwfHx8MA%3D%3D" alt="Card 3" />
    <h3>HIMS Software</h3>
    <p>Hospital Integrated Management Software (HIMS) to streamline hospital operations, including billing, records, and management.</p>
  </div>
</div>
<br/>
<br/>



      <br />
      <div style={styles.imageRowContainer}>
        {['https://images3.alphacoders.com/101/1010294.jpg', 
          'https://media.istockphoto.com/id/2015414448/photo/pharmaceutical-sales-representative-presenting-new-medication-to-doctors-in-medical-building.jpg?s=612x612&w=0&k=20&c=oJ6LASPPNYb2FHi_fTEqdivyoqQ_MfceC_cvZ4whM7k=', 
          'https://t3.ftcdn.net/jpg/07/95/19/34/360_F_795193432_IcSQendilOh3IdaD5bQXhJqGe8WCrOE2.jpg'].map((src, index) => (
          <div style={styles.imageBox} key={index}>
            <img src={src} alt={`Image ${index + 1}`} style={styles.image} />
          </div>
        ))}
      </div>
      <br/>
  <br/>
  <br/>

    
      <footer style={styles.footer}>
  <div style={styles.footerContainer}>
    <img src="https://i.postimg.cc/Nj9dgJ98/cards.png" alt="Payment Methods" style={styles.paymentMethods} />
  </div>

  <div style={styles.linksContainer}>
    <a href="/about" style={styles.link}>About Us</a>
    <a href="/contact" style={styles.link}>Contact</a>
    <a href="/privacy" style={styles.link}>Privacy Policy</a>
    <a href="/terms" style={styles.link}>Terms of Service</a>
  </div>

  <div style={styles.newsletterContainer}>
    <input
      type="email"
      placeholder="Subscribe to our newsletter"
      style={styles.newsletterInput}
    />
    <button style={styles.newsletterButton}>Subscribe</button>
  </div>

  <div style={styles.footerBottom}>
    <button style={styles.footerButton}>Back to Top</button>
    <button style={styles.footerButton}>Contact Support</button>
  </div>
</footer>


    </div>
  );
};

const styles = {
  
  topImageContainer: {
    position: 'relative',
    width: '100%',
    height: '500px',
    overflow: 'hidden',
  },
  topImage: {
    width: '100%',
    height: '100%',
    display: 'block',
    filter: 'blur(8px)', // Applies the blur effect
    objectFit: 'cover',
  },
  overlayText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Adds a translucent black background
    padding: '20px',
    borderRadius: '12px',
    fontSize: '24px',
    fontWeight: 'bold',
    width: '80%',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
  },
 
 


  
  
  imageCarousel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  carouselContainer: {
    display: 'flex',
    overflow: 'hidden',
    width: '75%',
    justifyContent: 'space-around',
    '@media (max-width: 768px)': {
      width: '90%',
    },
  },
  carouselImage: {
    width: '100px', // Adjusted for better responsiveness
    height: '100px',
    borderRadius: '8px',
    margin: '0 5px',
    transition: 'transform 0.3s ease',
    '@media (max-width: 768px)': {
      width: '80px',
      height: '80px',
    },
  },
  arrowButton: {
    fontSize: '24px',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    color: 'green',
    padding: '10px',
  },
  imageRowContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '20px',
  },
  imageBox: {
    flex: 1,
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    borderRadius: '8px',
    transition: 'transform 0.3s ease',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    maxWidth: '1400px',
    margin: 'auto',
    flexDirection: 'row', // Default to row layout for larger screens
  },
  image: {
    width: '100%',
    maxWidth: '300px',
    height: 'auto',
    marginRight: '20px',
  },
  textContainer: {
    flex: 1,
  },
  text: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
  },
  paragraph: {
    fontSize: '16px',
    color: '#333',
    marginTop: '10px',
  },
  // Mobile styles
  '@media (max-width: 768px)': {
    container: {
      flexDirection: 'column', // Stack elements vertically on smaller screens
      alignItems: 'center',    // Center-align contents
      textAlign: 'center',     // Center-align text in container
    },
    image: {
      marginRight: '0', // Remove right margin on mobile
      marginBottom: '20px', // Add bottom margin for spacing
    },
    text: {
      fontSize: '18px',
    },
    paragraph: {
      fontSize: '14px',
    },
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    flexWrap: 'wrap', // Allow cards to wrap
    gap: '20px', // Add gap between cards for better spacing
  },
  card: {
    flex: '1 1 calc(33% - 40px)', // Default: three cards per row
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'flex',
    
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    margin: '10px', // Margin between cards
  },
  cardImage: {
    width: '100%',
    height: 'auto',
    maxWidth: '280px',
    borderRadius: '8px',
    marginBottom: '15px',
    transition: 'transform 0.3s ease',
  },
  cardDescription: {
    fontSize: '14px',
    lineHeight: '1.6',
    color: '#666',
    marginTop: '10px',
  },
  // Hover effect for the card
  cardHover: {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
  },
  // Media query for responsiveness
  '@media (max-width: 768px)': {
    cardContainer: {
      justifyContent: 'center', // Center-align cards in a single column
      padding: '10px', // Adjust padding for smaller screens
    },
    card: {
      flex: '1 1 100%', // Full width for smaller screens
      maxWidth: 'none', // Ensure cards can take full width
      margin: '10px 0', // Adjust margin for better spacing in a single column layout
    },
  },
  
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  paymentMethods: {
    maxWidth: '200px',
    margin: '0 auto',
  },
  linksContainer: {
    marginBottom: '20px',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    margin: '0 15px',
    fontSize: '14px',
  },
  newsletterContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  newsletterInput: {
    padding: '10px',
    fontSize: '14px',
    border: 'none',
    borderRadius: '5px',
    marginRight: '10px',
    width: '250px',
  },
  newsletterButton: {
    padding: '10px 15px',
    backgroundColor: '#ff6f61',
    border: 'none',
    borderRadius: '5px',
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
  },
  footerBottom: {
    marginTop: '20px',
  },
  footerButton: {
    padding: '10px 15px',
    backgroundColor: '#444',
    border: 'none',
    borderRadius: '5px',
    color: '#fff',
    fontSize: '14px',
    margin: '0 10px',
    cursor: 'pointer',
  },
  
  
  
  
};


export default Home;
