import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, PieChart, Pie, Cell
} from 'recharts';
import { FaUserPlus, FaUserCheck, FaClock, FaCalendarAlt, FaPills, FaProcedures } from 'react-icons/fa';
import './pharmacy.css'; // Import the external CSS file

const Pharmacy = () => {
  const pharmacyData = [
    { category: 'Painkillers', inStock: 120, soldToday: 20, lowStock: 5, totalDoctors: 15, nursesOnDuty: 25 },
    { category: 'Antibiotics', inStock: 200, soldToday: 35, lowStock: 8, totalDoctors: 18, nursesOnDuty: 30 },
    { category: 'Supplements', inStock: 150, soldToday: 25, lowStock: 10, totalDoctors: 12, nursesOnDuty: 20 },
    { category: 'Vaccines', inStock: 90, soldToday: 15, lowStock: 3, totalDoctors: 10, nursesOnDuty: 18 },
    { category: 'Antivirals', inStock: 110, soldToday: 18, lowStock: 7, totalDoctors: 20, nursesOnDuty: 22 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A569BD'];

  const cardData = [
    { title: 'Total Medicines in Stock', value: pharmacyData.reduce((acc, data) => acc + data.inStock, 0), icon: <FaPills /> },
    { title: 'Total Sold Today', value: pharmacyData.reduce((acc, data) => acc + data.soldToday, 0), icon: <FaProcedures /> },
    { title: 'Low Stock Items', value: pharmacyData.reduce((acc, data) => acc + (data.lowStock <= 10 ? 1 : 0), 0), icon: <FaClock /> },
    { title: 'Staff on Duty', value: pharmacyData.reduce((acc, data) => acc + data.nursesOnDuty + data.totalDoctors, 0), icon: <FaUserCheck /> },
  ];

  return (
    <div className="dashboard-container">
      <br />
      <div className="table-container">
        <h1 className="header">Medicines Inventory</h1>
        <div className="responsive-table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th className="table-header">Category</th>
                <th className="table-header">In Stock</th>
                <th className="table-header">Sold Today</th>
                <th className="table-header">Low Stock Alert</th>
                <th className="table-header">Total Doctors</th>
                <th className="table-header">Nurses on Duty</th>
              </tr>
            </thead>
            <tbody>
              {pharmacyData.map((data, index) => (
                <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                  <td className="table-cell">{data.category}</td>
                  <td className="table-cell">{data.inStock}</td>
                  <td className="table-cell">{data.soldToday}</td>
                  <td className="table-cell">{data.lowStock}</td>
                  <td className="table-cell">{data.totalDoctors}</td>
                  <td className="table-cell">{data.nursesOnDuty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="cards-container">
        {cardData.map((card, index) => (
          <div key={index} className="card">
            <div className="card-icon">{card.icon}</div>
            <h3 className="card-title">{card.title}</h3>
            <p className="card-value">{card.value}</p>
          </div>
        ))}
      </div>
      <div className="chart-container">
        <div className="card-container">
          <BarChart width={250} height={250} data={pharmacyData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="inStock" fill="#82ca9d" />
            <Bar dataKey="soldToday" fill="#8884d8" />
            <Bar dataKey="lowStock" fill="#ffc658" />
          </BarChart>
        </div>
        <div className="card-container">
          <LineChart width={250} height={250} data={pharmacyData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="inStock" stroke="#8884d8" />
            <Line type="monotone" dataKey="soldToday" stroke="#82ca9d" />
          </LineChart>
        </div>
        <div className="card-container">
          <PieChart width={250} height={250}>
            <Pie
              data={pharmacyData}
              dataKey="soldToday"
              nameKey="category"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {pharmacyData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Pharmacy;
