import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './signup.css'; // Updated CSS file

const Signup = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        mobile: '',
        dateOfBirth: '',
        gender: '',
        address: '',
        file: null,
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        setFormData((prev) => ({ ...prev, file: e.target.files[0] }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous errors
        const csrfToken = Cookies.get('csrftoken');
        const formDataToSend = new FormData();

        // Adding data to FormData
        Object.entries(formData).forEach(([key, value]) => formDataToSend.append(key, value));

        try {
            const response = await axios.post('http://localhost:8000/api/signup/', formDataToSend, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                window.location.href = '/login';
            }
        } catch (error) {
            setError(
                error.response?.data?.error || 'An unexpected error occurred. Please try again.'
            );
        }
    };

    return (
        <div className="page1">
            <div className="container1">
                <div className="imageContainer1">
                    <img
                        src="https://static.vecteezy.com/system/resources/thumbnails/045/367/632/small_2x/the-word-sign-up-in-black-on-a-white-background-with-brush-paint-handwriting-style-pop-up-banner-on-blue-background-free-video.jpg"
                        alt="Signup Illustration"
                        className="image1"
                    />
                </div>
                <div className="formContainer1">
                    <h2 className="heading1">Sign Up</h2>
                    <form onSubmit={handleSubmit} className="form1">
                        {/* Input Fields */}
                        {['username', 'password', 'email', 'mobile', 'dateOfBirth'].map((field) => (
                            <div className="formGroup1" key={field}>
                                <label htmlFor={field} className="label1">
                                    {field.charAt(0).toUpperCase() + field.slice(1)}:
                                </label>
                                <input
                                    type={field === 'password' ? 'password' : 'text'}
                                    id={field}
                                    name={field}
                                    value={formData[field]}
                                    onChange={handleChange}
                                    className="input"
                                    required
                                />
                            </div>
                        ))}

                        {/* Gender Select */}
                        <div className="formGroup1">
                            <label htmlFor="gender" className="label1">Gender:</label>
                            <select
                                id="gender"
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                                className="input"
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>

                    

                        {/* File Upload */}
                        <div className="formGroup1">
                            <label htmlFor="file" className="label1">Profile Picture:</label>
                            <input
                                type="file"
                                id="file"
                                name="file"
                                onChange={handleFileChange}
                                className="input"
                                accept="image/*"
                            />
                        </div>

                        {/* Error Message */}
                        {error && <div className="error">{error}</div>}

                        {/* Submit Button */}
                        <button type="submit" className="button1">Sign Up</button>
                    </form>
                    <p className="loginText1">
                        Already have an account? <a href="/login" className="loginLink1">Login here</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Signup;
