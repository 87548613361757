import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { FaUserInjured, FaCalendarAlt, FaUserMd, FaBell } from 'react-icons/fa';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { LineChart, Line } from 'recharts';
import { FaHospital, FaMoneyBillAlt, FaComments, FaAmbulance } from 'react-icons/fa';
import {  RadialBarChart, RadialBar,  } from 'recharts';
import './Dashboard.css'; // Import the external CSS file



const Dashboard = () => {
  const [doctors, setDoctors] = useState([]);
  const [staff, setStaff] = useState([]); // New state for staff data
  const [pendingTasks, setPendingTasks] = useState([
    { task: 'Complete medical reports', date: '2024-11-08', status: 'Pending' },
    { task: 'Schedule patient follow-up', date: '2024-11-10', status: 'Pending' },
    { task: 'Update patient records', date: '2024-11-12', status: 'Pending' },
  ]);
  
const incomeExpenseData = [
  { name: 'Income', value: 70, fill: '#4CAF50' },
  { name: 'Expense', value: 30, fill: '#f44336' },
];

const patientTrendData = [
  { name: 'Patient Visits', value: 60, fill: '#8884d8' },
];
  const monthlyTrends = [
    { month: 'Jan', patientVisits: 300 },
    { month: 'Feb', patientVisits: 350 },
    { month: 'Mar', patientVisits: 400 },
    { month: 'Apr', patientVisits: 450 },
    { month: 'May', patientVisits: 480 },
    { month: 'Jun', patientVisits: 500 },
    { month: 'Jul', patientVisits: 520 },
    { month: 'Aug', patientVisits: 530 },
    { month: 'Sep', patientVisits: 550 },
    { month: 'Oct', patientVisits: 600 },
    { month: 'Nov', patientVisits: 620 },
    { month: 'Dec', patientVisits: 650 },
  ];

  // Sample data for the pie chart
  const patientData = [
    { name: 'Admitted', value: 120 },
    { name: 'Discharged', value: 80 },
    { name: 'Under Observation', value: 40 },
  ];

  // Sample data for yearly income and expenses
  const financeData = [
    { month: 'Jan', income: 12000, expense: 8000 },
    { month: 'Feb', income: 15000, expense: 9000 },
    { month: 'Mar', income: 18000, expense: 7000 },
    { month: 'Apr', income: 20000, expense: 11000 },
    { month: 'May', income: 22000, expense: 12000 },
    { month: 'Jun', income: 25000, expense: 13000 },
    { month: 'Jul', income: 27000, expense: 14000 },
    { month: 'Aug', income: 30000, expense: 15000 },
    { month: 'Sep', income: 32000, expense: 16000 },
    { month: 'Oct', income: 34000, expense: 17000 },
    { month: 'Nov', income: 35000, expense: 18000 },
    { month: 'Dec', income: 40000, expense: 19000 },
  ];

  const COLORS = ['#4CAF50', '#FF9800', '#f44336'];

  const fetchStaff = async () => {
    const data = [
      { name: 'Nurse Sarah', role: 'Nurse', available: true },
      { name: 'John Doe', role: 'Support Staff', available: false },
      { name: 'Emily Rose', role: 'Receptionist', available: true },
    ];
    setStaff(data);
  };
  const expensePatientChangeData = [
    { name: 'Jan', value: 50 },
    { name: 'Feb', value: 60 },
    { name: 'Mar', value: 70 },
    // Add more months or data as needed
  ];


  // Fetch doctors data
  useEffect(() => {
    const fetchDoctors = async () => {
      const data = [
        { name: 'Dr. John Doe', specialization: 'Cardiologist', available: true },
        { name: 'Dr. Jane Smith', specialization: 'Dermatologist', available: false },
        { name: 'Dr. William Johnson', specialization: 'Pediatrician', available: true },
        { name: 'Dr. Anoop', specialization: 'Pediatrician', available: true },
       // { name: 'Dr. Arya', specialization: 'Pediatrician', available: true },
       // { name: 'Dr. Amal', specialization: 'Pediatrician', available: true },
       
      ];
      setDoctors(data);
    };
    fetchDoctors();
  }, []);

  return (
    <div className="dashboard-containerneww">
       
      
      <br/>
      <br/>

      <h1 style={styles.header}>Hospital Management Dashboard</h1>

      <div style={styles.statsSection}>
  {/* Patient Statistics Card */}
  <div style={{ ...styles.statBox, ...styles.patientStats, backgroundColor: '#F5A623' }}>
    <FaUserInjured style={styles.icon} />
    <h2>Patient Statistics</h2>
    <p>Admitted: <span style={styles.statNumber}>120</span></p>
    <p>Discharged: <span style={styles.statNumber}>80</span></p>
    <p>Under Observation: <span style={styles.statNumber}>40</span></p>
  </div>

  {/* Appointments Overview Card */}
  <div style={{ ...styles.statBox, ...styles.appointmentsOverview, backgroundColor: '#4A90E2' }}>
    <FaCalendarAlt style={styles.icon} />
    <h2>Appointments Overview</h2>
    <p>Today: <span style={styles.statNumber}>25</span></p>
    <p>Upcoming: <span style={styles.statNumber}>60</span></p>
    <p>Canceled: <span style={styles.statNumber}>5</span></p>
  </div>

  {/* Staff Information Card */}
  <div style={{ ...styles.statBox, ...styles.staffInfo, backgroundColor: '#50E3C2' }}>
    <FaUserMd style={styles.icon} />
    <h2>Staff Information</h2>
    <p>Doctors: <span style={styles.statNumber}>45</span></p>
    <p>Nurses: <span style={styles.statNumber}>85</span></p>
    <p>Support Staff: <span style={styles.statNumber}>30</span></p>
  </div>

  {/* Notifications Card */}
  <div style={{ ...styles.statBox, ...styles.notifications, backgroundColor: '#D0021B' }}>
    <FaBell style={styles.icon} />
    <h2>Notifications</h2>
    <p>New messages: <span style={styles.statNumber}>3</span></p>
    <p>Pending tasks: <span style={styles.statNumber}>8</span></p>
    <p>Alerts: <span style={styles.statNumber}>2</span></p>
  </div>
</div>

<div style={styles.chartRow}>
<div style={styles.cardContainer}>
        <div style={styles.chartContainer}>
          <h2 style={styles.chartTitle}>Patient Statistics Overview</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Tooltip />
              <Legend />
              <Pie
                data={patientData}
                innerRadius={60}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {patientData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        </div>
        <div style={styles.cardContainer}>
        <div style={styles.chartContainer}>
          <h2 style={styles.chartTitle}>Yearly Income & Expenses</h2>
          
          
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={financeData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="income" fill="#4CAF50" name="Income" />
              <Bar dataKey="expense" fill="#f44336" name="Expense" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        </div>
        <div style={styles.cardContainer}>
        <div style={styles.chartContainer}>
          <h2 style={styles.chartTitle}>Monthly Patient Trends</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={monthlyTrends}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="patientVisits" stroke="#8884d8" name="Patient Visits" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      </div>
    
    
      <div style={styles.chartRow}>
  <div style={styles.doctorListContainer}>
    <div style={styles.doctorList}>
      {doctors.map((doctor, index) => (
        <div key={index} style={styles.doctorRow}>
          <img
            src="https://hips.hearstapps.com/hmg-prod/images/portrait-of-a-happy-young-doctor-in-his-clinic-royalty-free-image-1661432441.jpg"
            alt={doctor.name}
            style={styles.doctorImage}
          />
          <div style={styles.doctorInfo}>
            <strong style={styles.doctorName}>{doctor.name}</strong>
            <p style={styles.doctorSpecialization}>{doctor.specialization}</p>
            <p style={styles.doctorAvailability}>
              {doctor.available ? 'Available' : 'Not Available'}
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>

  

  {/* New Chart for Yearly Expense Patient Change */}
  <div style={styles.cardContainer}>
    <div style={styles.chartContainer}>
      <h2 style={styles.chartTitle}>Yearly Expense Patient Change</h2>
      <ResponsiveContainer width="100%" height={300}>
        <RadialBarChart cx="50%" cy="50%" innerRadius="30%" outerRadius="80%" barSize={10} data={expensePatientChangeData}>
          <RadialBar minAngle={15} label={{ fill: '#fff', position: 'insideStart' }} background clockWise dataKey="value" />
          <Tooltip />
          <Legend />
        </RadialBarChart>
      </ResponsiveContainer>
    </div>
  </div>

  <div style={styles.cardContainer}>
    <div style={styles.chartContainer}>
      <h2 style={styles.chartTitle}>Patient Visit</h2>
      <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Tooltip />
              <Legend />
              <Pie
                data={patientData}
                innerRadius={60}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {patientData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
    </div>
  </div>


 
</div>


   

      <div style={styles.staffInfoContainer}>
  {/* Staff Information Table */}
  <h2 style={styles.tableTitle}>Staff Information</h2>
  <table style={styles.table}>
    <thead style={styles.tableHead}>
      <tr>
        <th style={styles.tableCell}>Name</th>
        <th style={styles.tableCell}>Role</th>
        <th style={styles.tableCell}>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={styles.tableCell}>Anoop</td>
        <td style={styles.tableCell}>Nurse</td>
        <td style={styles.tableCell}>Active</td>
      </tr>
      <tr>
        <td style={styles.tableCell}>Manu</td>
        <td style={styles.tableCell}>Accountant</td>
        <td style={styles.tableCell}>Active</td>
      </tr>
      <tr>
        <td style={styles.tableCell}>Arjun</td>
        <td style={styles.tableCell}>Lab Assistant</td>
        <td style={styles.tableCell}>Active</td>
      </tr>
      <tr>
        <td style={styles.tableCell}>Madhav</td>
        <td style={styles.tableCell}>Technitian</td>
        <td style={styles.tableCell}>Active</td>
      </tr>
    </tbody>
  </table>
</div>

{/* Pending Task Details Table */}
<div style={styles.staffInfoContainer}>
  <h2 style={styles.tableTitle}>Pending Task Details</h2>
  <table style={styles.table}>
    <thead style={styles.tableHead}>
      <tr>
        <th style={styles.tableCell}>Task</th>
        <th style={styles.tableCell}>Date</th>
        <th style={styles.tableCell}>Status</th>
      </tr>
    </thead>
    <tbody>
      {pendingTasks.map((task, index) => (
        <tr key={index}>
          <td style={styles.tableCell}>{task.task}</td>
          <td style={styles.tableCell}>{task.date}</td>
          <td style={{ ...styles.tableCell, color: 'red' }}>{task.status}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
<br/>
<br/>
<div style={{ ...styles.statsSection,  padding: '20px', borderRadius: '10px' }}>
  {/* Card 1: Hospital Overview */}
  <div style={{ ...styles.statBox, backgroundColor: '#1E88E5', color: '#FFFFFF' }}>
    <FaHospital style={styles.icon} />
    <h2>Hospital Overview</h2>
    <p>Total Beds: <span style={styles.statNumber}>300</span></p>
    <p>Occupied Beds: <span style={styles.statNumber}>200</span></p>
    <p>Vacant Beds: <span style={styles.statNumber}>100</span></p>
  </div>

  {/* Card 2: Financial Summary */}
  <div style={{ ...styles.statBox, backgroundColor: '#8BC34A', color: '#FFFFFF' }}>
    <FaMoneyBillAlt style={styles.icon} />
    <h2>Financial Summary</h2>
    <p>Revenue: <span style={styles.statNumber}>$1.2M</span></p>
    <p>Expenses: <span style={styles.statNumber}>$900K</span></p>
    <p>Profit: <span style={styles.statNumber}>$300K</span></p>
  </div>

  {/* Card 3: Patient Feedback */}
  <div style={{ ...styles.statBox, backgroundColor: '#FFC107', color: '#121212' }}>
    <FaComments style={styles.icon} />
    <h2>Patient Feedback</h2>
    <p>Positive: <span style={styles.statNumber}>85%</span></p>
    <p>Neutral: <span style={styles.statNumber}>10%</span></p>
    <p>Negative: <span style={styles.statNumber}>5%</span></p>
  </div>

  {/* Card 4: Emergency Cases */}
  <div style={{ ...styles.statBox, backgroundColor: '#E53935', color: '#FFFFFF' }}>
    <FaAmbulance style={styles.icon} />
    <h2>Emergency Cases</h2>
    <p>Today: <span style={styles.statNumber}>15</span></p>
    <p>This Week: <span style={styles.statNumber}>75</span></p>
    <p>This Month: <span style={styles.statNumber}>300</span></p>
  </div>
</div>

      {/* Nested routes will be rendered here */}
      <Outlet />
    </div>
    
  );
};

const styles = {
  
  cardContainer: {
    backgroundColor: '#ffffff', // Card background color
    borderRadius: '8px', // Rounded corners
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow for card effect
    padding: '20px', // Padding inside the card
    margin: '20px auto', // Centered card with spacing
    maxWidth: '400px', // Maximum width for the card
  
    maxHeight: '400px', // Maximum width for the card
  },
  header: {
    textAlign: 'center',
    color: '#333',
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  statsSection: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
    width: '100%',
  },
  statBox: {
    width: '210px',
    height: '240px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#ffffff',
  },
  icon: {
    fontSize: '2.5rem',
    color: '#777',
    marginBottom: '10px',
  },
  statNumber: {
    fontWeight: 'bold',
    color: '#333',
  },
  chartRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    flexWrap: 'wrap',
    marginTop: '40px',
    width: '100%',
  },
  chartContainer: {
    flex: 1,
    maxWidth: '45%',
    minWidth: '300px',
    marginBottom: '20px',
  },
  chartTitle: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  doctorListContainer: {
    marginTop: '20px',
  
    width: '80%',
    maxWidth: '450px',
    backgroundColor: '#f0f0f0',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    margin: '0 auto', /* Centers the container horizontally */
    position: 'relative', /* Optional, can be removed */
  },
  
  doctorListTitle: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
  },
  
  doctorList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  
  doctorRow: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    flexWrap: 'wrap', /* Allows items to wrap on smaller screens */
  },
  
  doctorRowHover: {
    backgroundColor: '#e0e0e0',
  },
  
  doctorImage: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginRight: '15px',
    border: '2px solid #ddd',
  },
  
  doctorInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  
  doctorName: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0',
  },
  
  doctorSpecialization: {
    fontSize: '14px',
    color: '#555',
    margin: '5px 0',
  },
  
  doctorAvailability: {
    fontSize: '12px',
    color: '#777',
  },
  
  // Media query for mobile responsiveness
  '@media (max-width: 600px)': {
    doctorListContainer: {
      width: '100%', /* Full width on smaller screens */
      padding: '5px', /* Adjust padding for mobile */
    },
    
    doctorRow: {
      flexDirection: 'column', /* Stack the doctor info vertically on small screens */
      alignItems: 'flex-start', /* Align items to the left */
    },
  
    doctorImage: {
      width: '40px',
      height: '40px', /* Adjust the image size on mobile */
      marginRight: '0', /* Remove the margin between image and text */
      marginBottom: '10px', /* Space out the image and the info */
    },
  
    doctorName: {
      fontSize: '16px', /* Reduce font size for mobile */
    },
  
    doctorSpecialization: {
      fontSize: '12px', /* Adjust font size for mobile */
    },
  
    doctorAvailability: {
      fontSize: '10px', /* Adjust font size for mobile */
    },
  },
  
  staffInfoContainer: {
    width: '90%',
    marginTop: '40px',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  tableTitle: {
    textAlign: 'center',
    fontSize: '1.5rem',
    marginBottom: '15px',
    color: '#333',
  },
  
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    overflowX: 'auto', // Ensure horizontal scroll for large tables
  },
  tableHead: {
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '10px',
    textAlign: 'center',
  },
  
};


export default Dashboard;
